<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('order')"
                    id="order-tab"
                    data-toggle="tab"
                    href="#order"
                    role="tab"
                    aria-controls="order"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('list')"
                    id="list-tab"
                    data-toggle="tab"
                    href="#list"
                    role="tab"
                    aria-controls="list"
                    aria-selected="false"
                    >Danh sách VIP</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="list-tab"
                    data-toggle="tab"
                    href="#list"
                    role="tab"
                    aria-controls="list"
                    aria-selected="false"
                    >Nhật ký chạy VIP</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Link hoặc ID profile cần cài VIP:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="fb_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                                <div class="card card-orange mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div :class="['col-md-6']">
                                <label class="bold"
                                    >Số lượng <b class="bold">{{ type_text }}</b> nhỏ nhất cần tăng mỗi bài viết</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="min_like"
                                    class="form-control input-light"
                                />
                            </div>
                            <div :class="['col-md-6']">
                                <label class="bold"
                                    >Số lượng <b class="bold">{{ type_text }}</b> lớn nhất cần tăng mỗi bài viết</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="max_like"
                                    class="form-control input-light"
                                />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div v-if="type == 'vip_seeding_like_sl'" class="col-md-6">
                                <label class="text-danger bold">Tổng số bài viết cần đăng ký VIP:</label>
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="total_post"
                                    class="form-control input-light"
                                />
                            </div>
                            <div :class="['col-md-6']">
                                <label class="bold">Số lượng bài viết cần tăng trong 1 ngày</label>
                                <input
                                    type="number"
                                    placeholder="Số lượng bài viết cần tăng trong 1 ngày"
                                    v-model="max_post_daily"
                                    class="form-control input-light"
                                />
                            </div>
                            <div v-if="type != 'vip_seeding_like_sl'" class="col-md-6">
                                <label class="bold">Số ngày cần mua Vip:</label>
                                <input
                                    type="number"
                                    placeholder="Số ngày cần mua vip"
                                    v-model="days"
                                    class="form-control input-light"
                                />
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="card card-orange">
                                <div v-if="type != 'vip_seeding_like_sl'" class="card-body text-center bold">
                                    Tổng tiền của gói vip sẽ = (Giá tiền order mỗi like) x (Số lượng like lớn nhất mỗi
                                    bài) x (Số lượng bài trong ngày) x (Số ngày đăng ký vip)
                                </div>
                                <div v-else class="card-body text-center bold">
                                    Tổng tiền của gói vip sẽ = (Giá tiền order mỗi like) x (Số lượng like lớn nhất mỗi
                                    bài) x (Tổng số bài viết đăng ký vip)
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Giá tiền mỗi tương tác:</h6>
                                <h6 class="text-danger bold font-13">
                                    Giá thấp nhất: {{ price_default }} {{ currency }}
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    type="number"
                                    placeholder="Giá tiền bạn muốn bid cho mỗi tương tác"
                                    v-model="price"
                                    class="form-control form-control-line"
                                />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            <span
                                                >Bạn sẽ mua vip
                                                <span class="bold">{{ max_like }} {{ type_text }}</span>
                                                {{ type == "vip_seeding_like" ? "trong" : "tổng" }}
                                                <span class="bold">{{
                                                    type == "vip_seeding_like" ? days + " ngày" : total_post + " bài"
                                                }}</span>
                                                với giá <span class="bold">{{ priceSingle | formatNumber }}</span>
                                                {{ currency }} / {{ type_text }}.</span
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div v-if="type == 'vip_seeding_like_sl'" class="card card-orange mb-3">
                            <div class="card-body color-info">
                                <p class="">
                                    - <span class="font-bold">VIP Like theo số lượng</span> là gói Vip tự động tăng Like
                                    mỗi khi bạn đăng bài viết mới theo tổng số lượng bạn mua sẵn để tiết kiệm chi phí.
                                </p>
                                <p class="">
                                    - Ví dụ: Bạn mua 100 bài viết, mỗi bài viết 100 like, mỗi like giá 55đ thì tổng tiền
                                    bạn phải trả là <span class="font-bold">100 x 100 x 55 = 550.000đ</span>.
                                </p>
                                <p class=" mb-0">
                                    - Cữ mỗi lần bạn đăng bài hệ thống sẽ tự động tăng theo số like bạn chọn cho đến khi
                                    bạn đăng đủ 100 bài, do đó bạn có thể sử dụng gói này bao lâu cũng được cho đến khi
                                    đăng đủ 100 bài.
                                </p>
                            </div>
                        </div>
                        <div class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment,
                                    share....
                                </p>
                                <p>
                                    - Hỗ trợ trang cá nhân và fanpage. Khi đăng bài viết mới hệ thống sẽ tự động quét và
                                    tăng tương tác theo yêu cầu của gói
                                </p>
                                <p>
                                    - Không bảo hành số lượng tương tác, Tự động hoàn tiền số ngày chưa sử dụng nếu bạn
                                    hủy vip
                                </p>
                                <p>
                                    - Vui lòng lấy đúng id vip cần tạo và check kỹ vip tránh tạo nhầm, tính năng đang
                                    trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p class="mb-0">
                                    - Tốc độ tăng nhanh chậm ngẫu nhiên tránh bị facebook phát hiện, nếu muốn tăng nhanh
                                    vui lòng set giá mỗi tương tác cao hơn giá tối thiểu
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
                <datatable
                    :columns="
                        panel == 'logs'
                            ? columnsTableLogsOrder
                            : type == 'vip_seeding_like_sl'
                            ? columnsTableLogsSL
                            : columnsTableLogs
                    "
                    :rows="list_orders"
                >
                    <th v-if="panel != 'logs'" slot="thead-tr">
                        Thao Tác
                    </th>
                    <td
                        v-if="panel != 'logs'"
                        slot="tbody-tr"
                        slot-scope="props"
                        class="text-center"
                        style="vertical-align: middle;"
                    >
                        <button
                            v-if="type == 'vip_seeding_like'"
                            @click.stop.prevent="reNew_Order(props.row)"
                            type="button"
                            :class="['btn btn-sm btn-success mr-2']"
                        >
                            <i class="fas fa-cart-plus"></i>
                            Gia Hạn
                        </button>
                        <button
                            @click.stop.prevent="deleteVip(props.row)"
                            type="button"
                            :class="['btn btn-sm btn-danger align-items-center']"
                        >
                            <i class="fa fa-trash"></i>
                            Hủy Vip
                        </button>
                    </td>
                </datatable>
            </div>
        </div>
        <div
            v-if="reNewOrder"
            class="modal fade"
            id="reNew_Order"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Gia hạn VIP ID <span class="bold">{{ reNewOrder.fb_id }}</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <label
                                    >Số lượng <b class="font-bold">{{ type_text }}</b> nhỏ nhất cần tăng mỗi bài
                                    viết</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="min_like"
                                    class="form-control form-control-line"
                                />
                            </div>
                            <div class="col-md-6 col-12">
                                <label
                                    >Số lượng <b class="font-bold">{{ type_text }}</b> lớn nhất cần tăng mỗi bài
                                    viết</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="max_like"
                                    class="form-control form-control-line"
                                />
                            </div>
                            <div class="col-md-6 col-12">
                                <label>Số lượng bài viết cần tăng trong 1 ngày</label>
                                <input
                                    type="number"
                                    placeholder="Số lượng bài viết cần tăng trong 1 ngày"
                                    v-model="max_post_daily"
                                    class="form-control form-control-line"
                                />
                            </div>
                            <div class="col-md-6 col-12">
                                <label>Số ngày cần mua Vip:</label>
                                <input
                                    type="number"
                                    placeholder="Số lượng bài viết cần tăng trong 1 ngày"
                                    v-model="days"
                                    class="form-control form-control-line"
                                />
                            </div>
                            <div class="form-group col-md-12">
                                <div class="alert alert-info font-bold">
                                    <strong>Mẹo nhỏ: </strong> Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn
                                    cần gấp bạn có thể set giá job của mình cao hơn 1 vài đồng để chạy nhanh nhất có thể
                                    nhé
                                    <p class="text-danger mt-2 mb-0">
                                        Tổng tiền của gói vip sẽ = (Giá tiền order mỗi like) x (Số lượng like lớn nhất
                                        mỗi bài) x (Số lượng bài trong ngày) x (Số ngày đăng ký vip)
                                    </p>
                                </div>
                                <label>Giá tiền mỗi tương tác</label
                                ><label class="ml-1 text-danger font-bold">
                                    (Giá thấp nhất: {{ price_default }} {{ currency }})</label
                                >
                                <input
                                    type="number"
                                    placeholder="Giá tiền bạn muốn bid cho mỗi tương tác"
                                    v-model="price"
                                    class="form-control form-control-line"
                                />
                            </div>
                            <div
                                v-show="type_text === 'comment' || type_text === 'review'"
                                class="form-group col-md-12 mb-0"
                            >
                                <label class="mr-3">Nội dung seeding:</label>
                                <span class="badge badge-primary text-white">Số lượng: {{ quantity }}</span>
                                <textarea
                                    class="form-control"
                                    rows="10"
                                    v-model="list_messages"
                                    placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 lần seeding"
                                ></textarea>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="mr-3">Ghi chú:</label>
                                <textarea
                                    class="form-control"
                                    rows="3"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về order seeding của bạn"
                                ></textarea>
                            </div>
                            <div class="money-box text-center border border-primary mb-3">
                                <h4 class="font-weight-bold text-primary">
                                    {{ totalPrice | formatNumber }} {{ currency }}
                                </h4>
                                <span
                                    >Bạn sẽ mua vip
                                    <span class="text-primary">{{ quantity }} {{ type_text }}</span> trong
                                    <span class="text-primary">{{ days }} ngày</span> với giá
                                    <span class="text-primary">{{ priceSingle | formatNumber }}</span> {{ currency }} /
                                    {{ type_text }}.</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" :class="['btn btn-danger']" data-dismiss="modal">Hủy Bỏ</button>
                        <button @click="reNew_Order_Confirm()" type="button" :class="['btn btn-success']">
                            Đồng Ý
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError, formatNumber } from "../../../../helpers"
import {
    orderVipLike,
    orderVipLikeSL,
    convertUsernameToFbId,
    getListVipLike,
    getListVipLikeSL,
    getLogVip,
    deleteVipLike,
    deleteVipLikeSL
} from "../../../../api/services/facebook"
import datatable from "../../../../components/datatable/datatable"
export default {
    name: "facebook-order",
    components: {
        datatable
    },
    data() {
        return {
            list_orders: [],
            type: "vip_seeding_like",
            quantity: 1,
            object_id_search: "",
            limit_logs: 500,
            is_filter: false,
            fb_id: "",
            columnsTableLogs: [
                { label: "#", field: "index", index: true },
                { label: "Username", field: "username" },
                { label: "Uid", field: "fb_id", fb_link: true },
                { label: "Name", field: "fb_name" },
                { label: "Min", field: "min_like", numeric: true },
                { label: "Max", field: "max_like", numeric: true },
                { label: "Posts", field: "max_post_daily", numeric: true },
                { label: "Giá", field: "price_per", numeric: true },
                { label: "Tổng Tiền", field: "prices", numeric: true },
                { label: "Loại", field: "type", html: true },
                { label: "Hết Hạn", field: "time_expired", timeago: true },
                { label: "Khởi Tạo", field: "created_at", timeago: true },
                { label: "Ghi chú", field: "notes", textarea: true }
            ],
            columnsTableLogsSL: [
                { label: "#", field: "index", index: true },
                { label: "Username", field: "username" },
                { label: "Uid", field: "fb_id", fb_link: true },
                // { label: 'Name', field: 'fb_name' },
                { label: "Đã Chạy", field: "count_created_post", numeric: true },
                { label: "Tổng Post", field: "total_post", numeric: true },
                // { label: 'Loại', field: 'type', html: true },
                { label: "Min", field: "min_like", numeric: true },
                { label: "Max", field: "max_like", numeric: true },
                { label: "Giá", field: "price_per", numeric: true },
                { label: "Tổng Tiền", field: "prices", numeric: true },
                { label: "Khởi Tạo", field: "created_at", timeago: true },
                { label: "Ghi chú", field: "notes", textarea: true }
            ],
            columnsTableLogsOrder: [
                { label: "#", field: "index", index: true },
                { label: "Username", field: "username" },
                { label: "Uid", field: "fb_id", fb_link: true },
                { label: "Object Id", field: "object_id", fb_link: true },
                { label: "Loại", field: "type", html: true },
                { label: "Khởi Tạo", field: "created_at", timeago: true }
            ],
            price: 0,
            rows: [],
            days: 30,
            max_post_daily: 2,
            min_like: 40,
            max_like: 50,
            list_messages: [],
            notes: "",
            total_post: 10,
            panel: "",
            reNewOrder: {},
            price_default: 0
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 70)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        },
        totalPrice() {
            if (this.type === "vip_seeding_like") {
                return this.priceSingle * this.quantity * this.max_like * this.max_post_daily * this.days
            } else {
                return this.priceSingle * this.quantity * this.max_like * this.total_post
            }
        },
        type_text() {
            let type = this.type.replace("vip_seeding_", "")
            type = type.replace("_sl", "")
            return type
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        async fb_id() {
            if (this.fb_id.indexOf("facebook.com") > -1) {
                this.fb_id = await this.getFbId(this.fb_id)
            }
        },
        router(value) {
            if (value.includes("sl")) {
                this.type = "vip_seeding_like_sl"
            } else this.type = "vip_seeding_like"
            if (this.panel === "list") {
                this.handleShowPanel("list")
            }
            if (this.panel === "logs") {
                this.handleShowPanel("logs")
            }
        },
        type(value) {
            let prices = this.prices
            let price = prices.find(price => price.package_name === value)
            if (price) {
                this.price_default = price.price
                this.price = price.price
            }
        }
    },
    created() {
        if (this.$route.path.includes("sl")) {
            this.type = "vip_seeding_like_sl"
        } else this.type = "vip_seeding_like"
        let prices = this.prices
        let price = prices.find(price => price.package_name === this.type)
        if (price) {
            this.price_default = price.price
            this.price = price.price
        }
    },
    methods: {
        reNew_Order: async function(item) {
            this.reNewOrder = Object.assign({}, item)
            // eslint-disable-next-line no-undef
            $("#reNew_Order").modal("show")
        },
        reNew_Order_Confirm: async function() {
            if (this.price < this.price_default) {
                this.$swal.fire("Lỗi", "Giá cài đặt phải lớn hơn giá tối thiểu", "error")
                return
            }
            if (this.fb_id.length < 5 && !this.reNewOrder) {
                this.$swal("Lỗi", "Vui lòng nhập Id cần cài VIP", "error")
                return
            }
            if (parseInt(this.min_like) > parseInt(this.max_like)) {
                this.$swal("Lỗi", "Min like phải nhỏ hơn max like", "error")
                return
            }
            let check = await this.$swal({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                reverseButtons: true,
                html: `Bạn có chắc chắn muốn Gia Hạn gói VIP ${this.type_text}: ${
                    this.type === "vip_seeding_like" ? this.days + " ngày" : this.total_post + " bài"
                } <br> - ${this.max_like} ${this.type_text} / bài viết <br> - ${
                    this.max_post_daily
                } bài viết / ngày <br> - ${this.price} ${this.currency} / ${this.type_text} <br> Tổng tiền: ${
                    this.totalPrice
                }`
            })
            if (!check.value) {
                return
            }
            let postData = {
                fb_id: this.reNewOrder.fb_id,
                days: this.days,
                type: this.reNewOrder.type,
                price_per: this.price,
                min_like: this.min_like,
                max_like: this.max_like,
                quantity: this.max_like,
                notes: this.notes,
                max_post_daily: this.max_post_daily,
                renew: true
            }
            let data = await orderVipLike(postData)
            if (data.status === 200 && data.success) {
                let index = this.list_orders.indexOf(this.reNewOrder)
                let date = new Date(this.list_orders[index].time_expired)
                let newdate = date.setDate(date.getDate() + Number(this.days))
                this.list_orders[index].time_expired = new Date(newdate)
                this.$swal("Thành công", data.message, "success")
            } else {
                this.$swal.fire("Lỗi", catchError(data), "error")
            }
            // eslint-disable-next-line no-undef
            $("#reNew_Order").modal("hide")
        },
        order: async function() {
            if (this.fb_id.length < 5) {
                this.$swal("Lỗi", "Vui lòng nhập Id cần cài VIP", "error")
                return
            }
            if (parseInt(this.min_like) > parseInt(this.max_like)) {
                this.$swal("Lỗi", "Min like phải nhỏ hơn max like", "error")
                return
            }
            let check = await this.$swal({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                reverseButtons: true,
                html: `Bạn có chắc chắn muốn đăng ký gói VIP ${this.type_text}: ${
                    this.type === "vip_seeding_like" ? this.days + " ngày" : this.total_post + " bài"
                } <br> - ${this.max_like} ${this.type_text} / bài viết <br> - ${
                    this.max_post_daily
                } bài viết / ngày <br> - ${formatNumber(this.price)} ${this.currency} / ${
                    this.type_text
                } <br> Tổng tiền: ${formatNumber(this.totalPrice)}`
            })
            if (!check.value) {
                return
            }
            let postData = {
                fb_id: this.fb_id,
                days: this.days,
                type: this.type_text,
                price_per: this.price,
                min_like: this.min_like,
                max_like: this.max_like,
                quantity: this.max_like,
                notes: this.notes,
                max_post_daily: this.max_post_daily
            }
            let data = null
            if (this.type === "vip_seeding_like") {
                postData.days = this.days
                data = await orderVipLike(postData)
            } else {
                postData.total_post = this.total_post
                data = await orderVipLikeSL(postData)
            }
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", "Tạo gói Vip thành công !", "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        },
        deleteVip: async function(vip) {
            let check = await this.$swal({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                html: `Bạn có chắc chắn muốn hủy gói VIP này? <br> Bạn sẽ được hoàn lại số tiền những ngày vip chưa sử dụng và bị trừ thêm 10.000 ${this.currency} tiền phí hủy để tránh spam`
            })
            if (!check.value) {
                return
            }
            let data = null
            if (this.type === "vip_seeding_like") {
                data = await deleteVipLike(vip.id)
            } else {
                data = await deleteVipLikeSL(vip.orders_id)
            }
            if (data.status === 200 && data.success) {
                let index = this.list_orders.indexOf(vip)
                this.list_orders.splice(index, 1)
                this.$swal("Thông tin", "Bạn đã hủy vip thành công", "success")
            } else {
                this.$swal("Thông tin", data.message || "Bạn đã hủy vip thất bại", "error")
            }
        },
        getListVipSeedingOrder: async function() {
            let data = null
            if (this.type === "vip_seeding_like") {
                data = await getListVipLike()
            } else {
                data = await getListVipLikeSL()
            }
            if (data.data) {
                this.list_orders = data.data.map(order => {
                    order.type_html = order.type.toUpperCase()
                    order.list_messages_html = order.list_messages ? JSON.parse(order.list_messages).join("\n") : ""
                    order.status_html = order.is_refund
                        ? `<span class="badge badge-warning">Hoàn tiền</span>`
                        : (order.count_is_run >= order.quantity
                              ? `<span class="badge badge-success">Hoàn thành</span>`
                              : `<span class="badge text-white badge-primary">Đang chạy</span>`) +
                          `<span class="badge badge-success ml-1">${formatNumber(
                              order.count_success
                          )}</span><span class="badge badge-danger ml-1">${formatNumber(order.count_is_run)}</span>`
                    return order
                })
            }
        },
        getLogVipSeedingOrder: async function() {
            let data = null
            if (this.object_id_search) {
                if (this.type === "vip_seeding_like") {
                    data = await getLogVip(this.object_id_search, "vip_seeding_like", this.limit_logs)
                } else {
                    data = await getLogVip(this.object_id_search, "vip_seeding_like_sl", this.limit_logs)
                }
            } else {
                if (this.type === "vip_seeding_like") {
                    data = await getLogVip("", "vip_seeding_like", this.limit_logs)
                } else {
                    data = await getLogVip("", "vip_seeding_like_sl", this.limit_logs)
                }
            }
            if (data.data.data) {
                this.list_orders = data.data.data.map(order => {
                    order.type_html = order.type.toUpperCase()
                    order.list_messages_html = order.list_messages ? JSON.parse(order.list_messages).join("\n") : ""
                    order.status_html = order.is_refund
                        ? `<span class="badge badge-warning">Hoàn tiền</span>`
                        : (order.count_is_run >= order.quantity
                              ? `<span class="badge badge-success">Hoàn thành</span>`
                              : `<span class="badge text-white badge-primary">Đang chạy</span>`) +
                          `<span class="badge badge-success ml-1">${formatNumber(
                              order.count_success
                          )}</span><span class="badge badge-danger ml-1">${formatNumber(order.count_is_run)}</span>`
                    return order
                })
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
            if (panel === "list") {
                this.list_orders = []
                await this.getListVipSeedingOrder()
            }
            if (panel === "logs") {
                this.list_orders = []
                await this.getLogVipSeedingOrder()
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        return regex[1]
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        return data.data.id
                    } else {
                        return false
                    }
                }
            } else return true
        }
    }
}
</script>
